export const userAgreement =
`
大众汽车（中国）销售有限公司
宾利经销商员工培训线上报名系统
个人信息保护声明
更新日期：2021.06

大众汽车（中国）销售有限公司（以下简称“VGIC”）是在中华人民共和国登记的全国性销售公司，住所为天津自贸试验区（天津港保税区）天保大道188号开利大厦316室。VGIC拥有宾利汽车有限公司（Bentley Motors Limited）产品进口的独家授权。宾利汽车中国是VGIC所属部门。
宾利经销商员工培训线上报名系统(后称“系统”)用于宾利授权经销商人员进行线上报名注册及线上课程查询。VGIC委托迪迪艾咨询（北京）有限公司（以下简称“VGIC供应商”）在系统中收集和处理宾利经销商员工（以下简称“经销商员工”或者“您”）的个人信息。本声明适用于该系统中的经销商员工个人信息的使用、存储、共享、转让以及其它方式的信息处理行为。
本声明所述的个人信息是指以电子或者其他方式记录的能够单独或与其他信息结合识别特定自然人身份的各种信息。
请仔细阅读以下条款，当您使用本系统时，应以同意本声明为前提。如果我们的经销商员工对本声明有任何问题或疑虑，或是想对我们可能违反相关法律法规的行为进行投诉，请联系宾利经销商培训学院010 6531 3249，或者发送电子邮件到bentleyacademy@bentleymotorschina.com 。

本《大众汽车（中国）销售有限公司经销商员工培训线上报名系统个人信息隐私声明》（以下简称“声明”）将为我们的经销商员工提供以下信息：
1.我们如何收集并使用您的个人信息
2.我们如何保护您的个人信息
3.您的权利
4.我们如何处理儿童的个人信息
5.您的个人信息如何在全球转移
6.本声明如何更新
7.如何联系我们

负责任地处理个人信息是VGIC社会责任和法律责任的一部分，我们深知个人信息对经销商员工的重要性，并会尽力保护经销商员工的个人信息安全可靠。该声明是为了保证经销商员工的个人信息在中华人民共和国的网络安全法和其他适用法律法规下能够得到充分保护。我们将遵循适用法律法规和内部政策所规定的公平合法、明确用途、比例适当、公开透明、选择同意、确保安全等原则来保护我们经销商员工的个人信息。我们将采取适当的技术和组织安全措施来保护个人数据免受意外或非法破坏、意外丢失、更改、未经授权的披露或访问。
我们强烈建议您阅读以下说明，如有任何问题，请与我们联系（电话：010 6531 3249）。

1.我们如何收集并使用您的个人信息

1.1.我们收集哪些您的个人信息
在我们经销商员工同意的前提下，为了下列用途，VGIC可能会分别收集以下类型的个人信息：
•以经销商人才发展及培训活动为目的，我们可能收集您的姓名、称谓、电话号码、所属经销商、岗位信息、电邮地址、入职信息、工作经验信息课程学习记录等。
我们严格依据相关的法律法规来收集经销商员工的个人信息。
在经销商员工同意的前提下，我们可能通过以下渠道收集经销商员工的个人信息：
•通过宾利经销商培训线上报名系统，我们可能收集经销商员工的个人信息，收集的个人信息的类型仅限于在本声明中列出的类型，并且将仅用于本声明中列出的目的。

1.2.我们如何使用您的个人信息
经销商员工个人信息的用途如下：
•经销商人才发展及培训活动：经销商岗位人员参与培训报名，安排经销商人员进行认证及培训计划，查看自身学习培训进度等。

1.3.我们如何存储您的个人信息
我们非常认真地对待我们经销商员工的个人信息。我们经销商员工的个人信息存储在VGIC供应商的数据库里，这些数据库储存在采用了严格的物理安全措施和IT安全措施保护的设备中，访问权限很高。我们只有在为实现信息用途所必要的范围内才会将收集的个人信息保存在我们的数据库中。
通常情况下，我们对您个人信息的储存及处理仅存在于您参与培训及认证计划期间。若您退出培训及认证计划，或结束在品牌的任职工作，在此期限后，我们将会永久删除您的个人信息。

1.4.我们如何分享、转让、公开披露您的个人信息
1.4.1我们如何分享、转让您的个人信息
仅在下列情况下，我们才可能会与其他相关实体或个人共享、转让或披露经销商员工的个人信息（您的姓名、称谓、电话号码、所属经销商、岗位信息、电邮地址、入职信息、工作经验信息课程学习记录等）：
•经经销商员工明确同意，我们可以共享、转让或披露经销商员工的个人信息。
•我们可以根据法律、法规以及行政或司法机关的执法要求或其它强制性要求共享、转让或披露经销商员工的个人信息。
•在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此声明的约束，否则我们将要求该公司、组织重新向您征求授权同意。
•VGIC供应商必须严格遵守本声明，并采取必要措施保证经销商员工个人信息的机密性和安全性。我们绝不允许第三方为了未经授权的目的对经销商员工的个人信息进行共享、转让或披露。
1.4.2我们如何公开披露您的个人信息
我们仅会在以下情形下，公开披露您的个人信息：
•	获得您明确同意后；
•	在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。

2.我们如何保护您的个人信息
VGIC供应商采用符合公认行业标准的技术和组织安全措施来保护我们所掌握的个人信息不受意外或故意操控、损害、破坏以及未授权人员的访问。其安全措施包括访问控制、系统强化、网络漏洞扫描和管理、意识培训、日志和安全事件管理、传输和存储加密等方面，这些安全措施将根据我们的业务需求、科技的进步和监管要求不断进行必要的更新。
若发生个人信息安全事件，我们将按照内部设计的应急预案进行调查，并采取一切必要措施消除潜在风险，并依据相关法律法规告知经销商员工。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。

3.经销商员工权利
我们的经销商员工有权利通过线上和线下的联系渠道来访问、纠正、删除他们的个人信息，更改他们的授权范围或者关闭他们的账户。请联系宾利经销商培训学院010 6531 3249，或者发送电子邮件至： bentleyacademy@bentleymotorschina.com。
为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
我们将在三十天内作出答复。如果经销商员工对收到的答复仍不满意，可以向有关主管部门提出经销商员工投诉。如果经销商员工提出疑问，我们将根据对应的情况，尽力向经销商员工提供相关部门的信息。
对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
在以下情形中，我们将无法响应您的请求：
a)与个人信息控制者履行法律法规规定的义务相关的；
b)与国家安全、国防安全直接相关的；
c)与公共安全、公共卫生、重大公共利益直接相关的；
d)与刑事侦查、起诉、审判和执行判决等直接相关的；
e)个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
f)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
g)响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
h)涉及商业秘密的。

4.我们如何处理儿童的个人信息
经销商员工线上培训报名系统不收集任何儿童信息。 

5.您的个人信息如何在全球范围转移
经销商员工线上培训报名系统中所收集的个人数据在中国境内进行存储，我们不会将您的个人数据进行跨境转移。

6.本声明如何更新
我们可能会更新本声明。我们在发布此声明的更新时，会在此声明的顶部修改更新日期。对于重要更新，我们还会通过电子邮件、电话、推送通知或其他方式告知经销商员工。我们鼓励经销商员工随时查阅本声明，了解我们如何是保护经销商员工的个人信息的。

7.如何联系我们
如果我们的经销商员工对本声明有任何问题或疑虑，或是想对我们可能违反相关法律法规的行为进行投诉，请联系宾利经销商培训学院010 6531 3249或者发送电子邮件到bentleyacademy@bentleymotorschina.com。
大众汽车（中国）销售有限公司


`
